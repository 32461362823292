.el-dialog {
  max-width: 410px;
  width: calc(100% - #{($-grid-gutter * 2)});
}

.el-dialog__header {
  padding: $-padding-unit * 2;
  padding-bottom: $-padding-unit;
}
.el-dialog__body {
  padding: $-padding-unit * 2;
  word-break: break-word;
}
.el-dialog__footer {
  padding: $-padding-unit * 2;
  padding-top: $-padding-unit;
}

.el-dialog.dialog-size-550 {
  max-width: 550px;
  width: calc(100% - #{($-grid-gutter * 2)});
}

.el-dialog.dialog-size-640 {
  max-width: 640px;
  width: calc(100% - #{($-grid-gutter * 2)});
}

.el-dialog.dialog-size-1024 {
  max-width: 1024px;
  width: calc(100% - #{($-grid-gutter * 2)});
}

.el-dialog.play-game-dialog {
  border-radius: $-border-radius;
}
.el-dialog.play-game-dialog .el-dialog__header,
.el-dialog.play-game-dialog .el-dialog__footer {
  padding: 0;
}
.el-dialog.play-game-dialog .el-dialog__headerbtn .el-dialog__close {
  color: $-color-white;
}

.el-dialog.soft-skill-dialog .el-dialog__header,
.el-dialog.soft-skill-dialog .el-dialog__body {
  padding: 0;
}

.el-dialog .el-button {
  font-weight: 400;
}
