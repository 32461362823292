.el-collapse {
  margin: 0 ($-padding-unit * 2);
}
.el-collapse--no-top-border {
  border-top-color: transparent;
}

.el-collapse-item--soft-skills {
  position: relative;
  margin-top: - $-padding-unit * 2;
}
.el-collapse-item--soft-skills.is-active:after {
  position: absolute;
  content: '';
  right: 0;
  left: 0;
  height: 1px;
  background-color: $--border-color-lighter;
  @include res('xs') {
    content: unset;
  }
}
.el-collapse-item--soft-skills .el-collapse-item__wrap {
  margin-right: - $-padding-unit * 2;
  margin-left: - $-padding-unit * 2;
  border: 0;
}
.el-collapse-item--soft-skills .el-collapse-item__content {
  padding-bottom: $-padding-unit * 2;
  @include res('xs') {
    padding-bottom: 0;
  }
}

.el-collapse-item__header {
  height: 64px;
  line-height: 64px;
  font-weight: 700;
  font-size: $-font-size-m;
}

.el-collapse-item__content {
  padding-bottom: $-padding-unit * 4;
  font-size: $-font-size-m;
}

.el-collapse-item__arrow {
  line-height: 64px;
  font-size: $-font-size-l;
}

.el-collapse-item__arrow.el-icon-arrow-right.is-active {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.el-collapse-item__arrow.el-icon-arrow-right:before {
  content: "\e790"; // display caret icon
}

// Dashboard overview accordion
.el-collapse.overview-accordion {
  margin: 0;
  border-top: 0;
}
.el-collapse.overview-accordion .el-collapse-item__header {
  font-size: $-font-size-xl;
  background-color: transparent;
}
.el-collapse.overview-accordion .el-collapse-item__wrap {
  background-color: transparent;
}
.el-collapse.overview-accordion .el-collapse-item__content {
  padding-bottom: $-padding-unit;
}
