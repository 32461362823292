$size: 50px;

.mega-switch {

  &.el-switch {
    height: $size;
    line-height: $size;
  }

  .el-switch__core {
    height: $size;
    width: $size * 2 !important;
    border-radius: 30px;

    &:after {
      width: $size - 4;
      height: $size - 4;
    }
  }

  &.el-switch.is-checked .el-switch__core {
    border-color: $-color-orange-3;
    background-color: $-color-orange-3;

    &:after {
      margin-left: - ($size - 3);
      background-color: $-color-orange;
    }
  }
}
