@import "~vars";

// Custom mixins should be imported individually in each file we want to use them
// because of override issue with element-ui mixins

@import "~element-ui/packages/theme-chalk/src/index";

// Utility classes
// http://element.eleme.io/#/en-US/component/layout#utility-classes-for-hiding-elements
// @import 'element-ui/lib/theme-chalk/display.css';

@import "_badge";
@import "_button";
@import "_collapse";
@import "_dialog";
@import "_form";
@import "_pagination";
@import "_progress";
@import "_rate";
@import "_steps";
@import "_switch";
@import "_table";
@import "_tabs";

.v-modal {
  background-color: $-color-primary;
  opacity: 0.85;
}

.el-popover {
  width: 360px;
  text-align: left;
  word-break: normal;
}

.dropdown-user-menu {
  width: 232px;

  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    color: $-color-primary;
    font-weight: 700;
    background-color: #f5f7fa;
  }
}
