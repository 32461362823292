@import "~mixins";

.el-step.is-horizontal {
  .el-step__title {
    font-size: $-font-size-xxs;
    line-height: 20px;

    @include res('xs') { line-height: 12px; }
  }

  .el-step__title.is-process,
  .el-step__title.is-success {
    font-weight: 700;
  }
  
  .el-step__icon {
    width: 30px;
    height: 30px;
  }
  
  .el-step__line {
    top: 14px;
  }

  .el-step__icon-inner.is-status.el-icon-check {
    font-size: 18px;
  }
}
