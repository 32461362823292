// Form gutter //
.el-form-item {
  margin-bottom: $-grid-gutter;
}

// Form Label //
.el-form-item__label {
  display: block;
  float: none;
  padding: 0;
  font-size: $-font-size-s;
  font-weight: 600;
  line-height: 2.5;
  color: $-font-color-secondary;
}

// Input //
.el-input__inner {
  @include placeholder {
    font-weight: 300;
    font-style: italic;
  }
}

.el-input--prefix-padding .el-input__inner {
  padding-left: $-padding-unit * 3/2;
}

// Input append //
.el-input-group__append {
  background-color: #ffffff;
}

.el-input-group__append button.el-button {
  background-color: #ffffff;
  font-size: $-font-size-s;
  font-weight: 600;
  color: $-font-color-primary;
}

.el-input-group__append:hover,
.el-input-group__append:focus {
  border-color: $-color-primary;
}

.el-input-group__append:hover:after,
.el-input-group__append:focus:after {
  content: '';
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  width: 1px;
  background-color: $-color-primary;
}

.input-append--light .el-input-group__append .el-button--default {
  font-weight: 300;
}

// Radio //
.el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled){
  box-shadow: 0 0 0 1px $--radio-button-checked-border-color;
}

.el-radio-group__three-choices {
  width: 100%;

  .el-radio-button {
    width: 33.33%;
  }

  .el-radio-button__inner {
    width: 100%;
  }
}

.el-radio-group__two-choices {
  width: 100%;

  .el-radio-button {
    width: 50%;
  }

  .el-radio-button__inner {
    width: 100%;
  }
}


.el-checkbox-group__half-width {
  width: 100%;

  .el-checkbox {
    width: 50%;
    margin-right: 0;
  }
}

// Select //
.el-select {
  color: $-color-dark;

  .el-input {
    font-size: $-font-size-s;
  }

  .el-input .el-select__caret {
    color: $-color-dark;
  }

  .el-input .el-select__caret.el-icon-arrow-up:before {
    font-family: 'FontAwesome';
    content: "\F0D8";
  }
}

// Checkbox //
.el-checkbox.font-size-s .el-checkbox__label {
  font-size: $-font-size-s;
}

.el-checkbox-group__three-choices.dialog-max-width {
  width: 100%;

  .el-checkbox-button {
    width: 33.33%;
  }

  .el-checkbox-button__inner {
    width: 100%;
  }
}
