/* open-sans-300 - latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('~assets/fonts/open-sans/open-sans-v15-latin_greek-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-300.woff') format('woff'), /* Modern Browsers */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('~assets/fonts/open-sans/open-sans-v15-latin_greek-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-regular.woff') format('woff'), /* Modern Browsers */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url('~assets/fonts/open-sans/open-sans-v15-latin_greek-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-600.woff') format('woff'), /* Modern Browsers */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url('~assets/fonts/open-sans/open-sans-v15-latin_greek-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-700.woff') format('woff'), /* Modern Browsers */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-display: auto;
  src: url('~assets/fonts/open-sans/open-sans-v15-latin_greek-800.eot'); /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-800.woff') format('woff'), /* Modern Browsers */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('~assets/fonts/open-sans/open-sans-v15-latin_greek-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}