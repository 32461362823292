.el-badge__content {
  font-size: $-font-size-xs;
  font-weight: 700;
  color: $-font-color-primary;
  background-color: $-color-orange;
}

.el-badge-report-card .el-badge__content.is-fixed {
  top: 16px;
  right: 2px;
}
