// Typography initialization
h1 { font-size: $-font-size-xxxl; }
h2 { font-size: $-font-size-xxl; }
h3 { font-size: $-font-size-xl; }
h4 { font-size: $-font-size-l; }
h5 { font-size: $-font-size-m; }
h6 { font-size: $-font-size-s; }
p { font-size: $-font-size-m; }
small { font-size: $-font-size-xs; }

h1, h2, h3, h4, h5, h6, p, small { margin: $-padding-unit 0; }

.width-100 { width: 100%; }

.relative { position: relative; }

.block { display: block; }
.inline { display: inline; }
.inline-block { display: inline-block; }

.float-left { float: left; }
.float-right { float: right; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.cursor-pointer { cursor: pointer; }

.margin-right-unit { margin-right: $-padding-unit; }
.margin-bottom-unit { margin-bottom: $-padding-unit; }

.fill-height-or-more {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-column {
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-1 { flex: 1; }

.align-center {
  -webkit-align-items: center;
  align-items: center;
}
.align-baseline {
  -webkit-align-items: baseline;
  align-items: baseline;
}

.justify-center { justify-content: center; }
.justify-space-between { justify-content: space-between; }
.justify-space-around { justify-content: space-around; }
.justify-flex-end { justify-content: flex-end; }

a { text-decoration: none; }
a, a:visited, a:focus, a:active { color: inherit; }

.text-underline { text-decoration: underline; }
