



























@import "~vars";

.container {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: $-grid-width;
  padding: $-grid-gutter;
}

.container.sjt-container { max-width: 1024px; }
.container.form-container { max-width: 410px; }

.container.no-gutter { padding: 0; }

.container:before,
.container:after {
  content: "";
  display: table;
}
.container:after {
  clear: both;
}

.container > .container { padding: 0; }
