@include keyframe('growing-fadeIn') {
  from {
    opacity: 0;
    background-size: 0%;
  }

  to {
    opacity: 1;
    background-size: 100%;
  }
}

// Similar to elements transitions but with opposite direction
.card-view-right-enter-active,
.card-view-right-leave-active {
  opacity: 1;
  transform: scale(1, 1);
  transform-origin: center right;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.card-view-right-enter,
.card-view-right-leave-active {
  opacity: 0;
  transform: scale(.45, 1);
}

.card-view-curtain-enter-active,
.card-view-curtain-leave-active {
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.card-view-curtain-enter,
.card-view-curtain-leave-active {
  opacity: 0;
}
