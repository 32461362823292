

































@import "~vars";

.back-link {
  letter-spacing: 0.1px;
  font-weight: 600;
  font-size: $-font-size-s;

  .fa-angle-left {
    font-size: 26px;
    padding-right: 10px;
  }
}
