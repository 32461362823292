@import "~mixins";

.el-tabs__header {
  margin-bottom: $-grid-gutter;
}

.el-tabs__nav-wrap::after {
  background-color: $-color-grey;
}

// Custom centering tab content function
// include center class to an el-tab component
// works only with top or bottom positioning
.el-tabs--top.center,
.el-tabs--bottom.center {
  .el-tabs__nav {
    float: none;
    text-align: center;
  }
  
  .el-tabs__active-bar {
    display: none;
  }
  
  .el-tabs__item,
  .el-tabs__item.is-top:nth-child(2),
  .el-tabs__item.is-bottom:nth-child(2),
  .el-tabs__item.is-top:last-child,
  .el-tabs__item.is-bottom:last-child {
    padding: 0 40px;

    @include res('xs') {
      padding: 0 12px;
    }
  }

  .el-tabs__item.is-active:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: $--color-primary;
  }
}

.el-tabs--top.center.tabs--header,
.el-tabs--bottom.center.tabs--header {
  .el-tabs__header {
    margin-bottom: 0;
  }

  .el-tabs__nav-wrap::after {
    content: unset;
  }
}

.el-tabs__item {
  height: 50px;
  line-height: 50px;
  letter-spacing: 0.1px;
}

.el-tabs__item.is-active {
  font-weight: 700;
}

.el-tabs__item:focus.is-active.is-focus:not(:active) {
  box-shadow: unset;
}
