.el-button { font-weight: 600; }

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:hover,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:active {
  background-color: $-color-primary-2;
  border-color: $-color-primary-2;
}


// Secondary Button //
.el-button--secondary {
  color: $-color-primary-9;
  background-color: transparent;
  border-color: $-color-primary-9;
}
.el-button--secondary:hover,
.el-button--secondary:focus,
.el-button--secondary:active {
  color: $-color-primary;
  background-color: rgba(255,255,255,0.2);
  border-color: $-color-primary;
}
.el-button--secondary.is-disabled,
.el-button--secondary.is-disabled:hover,
.el-button--secondary.is-disabled:focus,
.el-button--secondary.is-disabled:active {
  color: $-color-primary-7;
  background-color: rgba(255,255,255,0.1);
  border-color: $-color-primary-7;
}


// Secondary Button White Content //
.el-button--secondary.has-white-content,
.el-button--secondary.has-white-content:hover,
.el-button--secondary.has-white-content:focus,
.el-button--secondary.has-white-content:active {
  color: $-color-white;
  border-color: $-color-white;
}
.el-button--secondary.has-white-content.is-disabled,
.el-button--secondary.has-white-content.is-disabled:hover,
.el-button--secondary.has-white-content.is-disabled:focus,
.el-button--secondary.has-white-content.is-disabled:active {
  color: $-color-primary-2;
  border-color: rgba(255,255,255,0.4);
}


// Game Button //
.el-button--game {
  color: $-color-white;
  background-color: $-color-orange;
  border-color: $-color-orange;
}

.el-button--game:hover,
.el-button--game:focus,
.el-button--game:active {
  color: $-color-white;
  background-color: $-color-orange-5;
  border-color: $-color-orange;
}

.el-button--game.is-disabled,
.el-button--game.is-disabled:hover,
.el-button--game.is-disabled:focus,
.el-button--game.is-disabled:active {
  opacity: 0.5;
  color: $-color-white;
  background-color: $-color-orange;
  border-color: $-color-orange;
}

// Excel Button

// Game Button //
.el-button--excel {
  color: $-color-white;
  background-color: #1D6F42;
  border-color: #1D6F42;
}

.el-button--excel:hover,
.el-button--excel:focus,
.el-button--excel:active {
  color: $-color-white;
  background-color: #31bd70;
  border-color: #31bd70;
}

.el-button--excel.is-disabled,
.el-button--excel.is-disabled:hover,
.el-button--excel.is-disabled:focus,
.el-button--excel.is-disabled:active {
  opacity: 0.5;
  color: $-color-white;
  background-color: $-color-orange;
  border-color: $-color-orange;
}
