.el-pagination.has-top-padding {
  margin-top: $-padding-unit * 5;
}

.el-pagination.is-centered {
  text-align: center;
}

.el-pagination.is-background {
  .el-pager {
    font-weight: 400;
  }

  .el-pager li {
    height: 30px;
    font-size: $-font-size-m;
    line-height: 30px;
  }

  .el-pager .number:not(.active) {
    color: $-color-primary-5;
    background-color: $-color-white;
  }

  .el-pager .number:not(.active):hover {
    line-height: 28px;
    color: $-font-color-primary;
    border: 1px solid $-color-primary;
  }

  button {
    height: 30px;
    line-height: 30px;
  }

  button.btn-prev,
  button.btn-next {
    background-color: $-color-primary-1;

    .el-icon {
      font-size: 14px; 
    }
  }
}
