.el-table {
  border-radius: $-border-radius;

  thead {
    color: $-color-primary;
  }

  th>.cell,
  .cell {
    padding-right: $-padding-unit;
    padding-left: $-padding-unit;
    word-break: break-word;
  }

  th.el-table-column--selection,
  td.el-table-column--selection {
    text-align: center;

    .cell {
      padding-right: 14px;
      padding-left: 14px;
    }
  }

  th.el-table-column--selection .cell,
  td.el-table-column--selection .cell {
    padding-right: 14px;
    padding-left: 14px;
  }

  .COMPLETED { color: #67C23A; }
  .PENDING { color: #E6A23C; }
  .EXPIRED { color: #F56C6C; }
}

.el-table--border th,
.el-table--border td {
  border-right: 0;
}
